import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class TopFeatures extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h4 bluecolor">Top Features </span>
               Of our Tron Token Development</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Transparency</h3>
                <p className="pharagraph">All the transactions are held in public, which makes it easily trackable in case of any dispute.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/tron/Transparency.png" alt="Transparency" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/tron/Affordability.png" alt="Affordability" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h3 className="heading-h4">Affordability</h3>
                <p className="pharagraph">When compared to the transactions that happens in other popular blockchain networks, the cost per transaction is less.</p>
                
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">High Scalability</h3>
                <p className="pharagraph">It is highly scalable in nature and has the ability to handle over 2000 transactions per second.</p>
                
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/tron/High-Scalability.png" alt="High Scalability" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/tron/Enables-Swapping-Tokens.png" alt="Enables Swapping Tokens" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h3 className="heading-h4">Enables Swapping Tokens</h3>
                <p className="pharagraph">The Tron based tokens can be swapped while using it in the Dapps, which makes the transactions faster.</p>
                
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Unlimited Access</h3>
                <p className="pharagraph">The users will have unlimited access to data, hence the transfer of data or token can be done without any inconvenience.</p>
                
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/tron/Unlimited-Access.png" alt="Unlimited Access" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures